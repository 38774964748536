import React from "react";
import { IoPulse, IoTelescope, IoTime } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const Reporting = (props) => (
  <Layout>
    <Seo
      title="Reporting | Karmly Feature"
      description="Build your own reports and track revenue, costs & gross margin in real time to keep your finger on the pulse of your business on a daily basis."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title="Reporting"
      description="Track revenue, costs and gross margin in real time to keep your finger on the pulse of your business on a daily basis. Build your own reports to keep track of your key recruitment metrics. You can report on any data held within Karmly."
      image="InvoxyFeature_Forecast.png"
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoPulse />
              <br />
              Actuals
            </h3>
            <p>
              Track your revenue and profit in real time as timesheets are
              entered and approved.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoTelescope />
              <br />
              Go Deep
            </h3>
            <p>
              Get in-depth analysis by creating your own report parameters and
              filters to see the exact detail you need.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoTime />
              <br />
              Real Time
            </h3>
            <p>
              Saved reports are up-to-date with live figures every time you
              login.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Reporting_Profit.png"
              alt="Revenue Reporting & Projections"
            />
          </Box>
          <Box size={50}>
            <h3>Report on Revenue – Before It Comes In</h3>
            <p>
              Karmly gives you revenue, cost and profit data in real time based
              on every timesheet submitted and approved. Your accounting
              software can give you the numbers for the previous month, but
              Karmly gives you a detailed breakdown of where you stand on a
              daily basis, even before you invoice your clients.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Forecast Performance</h3>
            <p>
              Our forecast tool shows you projected revenue based on contract
              dates and work patterns from each contractor over the previous six
              weeks. Broken down by client, you can quickly see where revenue is
              due to drop off and you need to make more sales.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Reporting_Forecast.png"
              alt="Forcast Reporting"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Reporting_Insight.png"
              alt="Advanced Insights"
            />
          </Box>
          <Box size={50}>
            <h3>Advanced Business Intelligence</h3>
            <p>
              Karmly gives you deep insights into the performance of your
              business with all the tools you need to report on timesheets,
              invoices, revenue and margin. Take that to the next level and
              slice and dice your Karmly data with other cloud-based business
              apps using our PowerBI integration.
            </p>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default Reporting;
